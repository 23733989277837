/*
=========================================================
* CarbonAGX Website
=========================================================
* 
* Copyright 2022 CarbonAGX
=========================================================
* 
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// CarbonAGX Website examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/tree-yellow-flowers.jpg";
import unhLogo from "assets/images/partner-logos/colsa_stacked-blue-web-hr.png";
import oxbridgeAiLogo from "assets/images/oxbridge-ai-logo.png";

import { useEffect } from "react";
import { Box, Card, Link } from "@mui/material";
// Images
// import Partners from "pages/AboutUs/sections/Partners";

function Partners() {
  useEffect(() => {
    document.title = "Partners";
  }, []);
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.3),
              rgba(gradients.dark.state, 0.3)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          pb: { xs: 12, sm: 12, md: 8, lg: 6, xl: 6 }
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={12}>
              <MKTypography align="center" variant="h2" color="white" mt={{ xs: 16, sm: 16, md: 8 }} mb={1}>
                Our Partners
              </MKTypography>
            </Grid>
            <Grid item xs={12} alignContent="center">
              <MKTypography align="center" variant="h5" color="white" mt={{ xs: 1, sm: 2 }}>
                CarbonAGX is working in partnership with industry leaders in regenerative
                agriculture and climate finance.
              </MKTypography>
              <MKTypography align="center" variant="h5" color="white" mb={1}>
                Our partnerships aim to build relationships
                with organizations that support ranchers and farmers with meaningful tools,
                research, and financial incentives to support their efforts in regenerative agriculture.
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          py: 2,
          mx: { xs: 0, sm: 2, lg: 3 },
          mt: { xs: -6, md: -8 },
          mb: 4,
          backgroundColor: ({ palette: { light }, functions: { rgba } }) => rgba(light.main, 1),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          textAlign: "center",
          filter: "grayscale(50%)",
          // display: 'flex', // Apply flexbox
          // flexDirection: { xs: 'column', sm: 'column', md: 'row' }, // Stack vertically on small screens, horizontally on medium and larger screens
          // justifyContent: 'space-evenly', // Evenly space the child elements
          // alignItems: 'center', // Align items vertically in the center
        }}
      >
        <Grid container direction={{
          xs: 'column', // vertical alignment at 'xs'
          sm: 'column', // vertical alignment at 'sm'
          md: 'row', // horizontal alignment at 'md' and up
        }} alignItems="center">
          <Grid item xs>
            <Link href="https://colsa.unh.edu" target="_blank">
              <Box
                component="img"
                src={unhLogo}
                width={{ xs: "48%", sm: "45%", md: "40%", lg: "40%", xl: "40%" }}
              //sx={{ py: { xs: 1, sm: 1, md: 2 }, px: { xs: 1, sm: 1, md: 1, lg: 2 } }}
              />
            </Link>
          </Grid>
          <Grid item xs>
            <Link href="https://www.tuki.ltd/oxbridge-ai-challenge" target="_blank">
              <Box
                component="img"
                src={oxbridgeAiLogo}
                width={{ xs: "48%", sm: "50%", md: "40%", lg: "40%", xl: "40%" }}
                sx={{ py: { xs: 1, sm: 1, md: 2 }, px: { xs: 1, sm: 1, md: 1, lg: 2 } }}
              />
            </Link>
          </Grid>
        </Grid>
      </Card>
      {/* <MKBox>
        <Container>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                borderRadius="50%"
                component="img"
                src="https://via.placeholder.com/150/008000/000000?text=partner"
                alt="future partner"
                width="100%"
                opacity={0.1}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                borderRadius="50%"
                component="img"
                src="https://via.placeholder.com/150/008000/000000?text=Partner"
                alt="future partner"
                width="100%"
                opacity={0.5}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                borderRadius="50%"
                component="img"
                src="https://via.placeholder.com/150/008000/000000?text=partner"
                alt="future partner"
                width="100%"
                opacity={0.1}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                borderRadius="50%"
                component="img"
                src="https://via.placeholder.com/150/008000/000000?text=partner"
                alt="future partner"
                width="100%"
                opacity={0.1}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                borderRadius="50%"
                component="img"
                src="https://via.placeholder.com/150/008000/000000?text=partner"
                alt="partner"
                width="100%"
                opacity={0.1}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                borderRadius="50%"
                component="img"
                src="https://via.placeholder.com/150/008000/000000?text=partner"
                alt="future partner"
                width="100%"
                opacity={0.1}
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox> */}

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Partners;